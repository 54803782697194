<template>
  <b-container fluid>
    <b-col md="12">
      <div class="w-full d-flex align-items-center py-3">
        <b-form-group label="Select Status">
          <b-form-select  v-model="type" :options="types"
                          @change="getLocalVideos($event)" class="m-0"
                          style="width: 300px;"
          ></b-form-select>
        </b-form-group>
        <div class="ml-auto">
          <b-button variant="primary" disabled>
            Total <b-badge variant="light">{{ total }}</b-badge>
          </b-button>
        </div>
      </div>
      <b-table
        ref="table"
        id="my-table"
        :fields="fields"
        :filter="searchKeyword"
        :items="videos"
        :busy.sync="isBusy"
        :per-page="perPage"
        @sort-changed="sortingChanged"
        small
        borderless
        hover
      >

        <template #cell(copy_status)="row">
          <span v-if="row.item.copy_status === 0">Not copied</span>
          <span v-if="row.item.copy_status === 1">Queued</span>
          <span v-if="row.item.copy_status === 2">Started</span>
          <span v-if="row.item.copy_status === 3">Completed</span>
          <span v-if="row.item.copy_status === 4">Failed</span>
          <span v-if="row.item.copy_status === 5">Ignored</span>
          <span v-if="row.item.copy_status === 6">Skipped</span>
          <div v-if="row.item.copy_status === 4">
          <b-button  size="sm" @click="ignoreVideo(row.item.id)" class="mr-2">
            Handled
          </b-button>
            <b-alert :show="updated == row.item.id" variant="success" size="sm">Updated</b-alert>
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button size="sm" @click="showModal(row.item.id)" class="mr-2">
            Events Log
          </b-button>
        </template>

      </b-table>

      <div class="overflow-auto">
        <b-pagination
          v-model="page"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="my-table"
          @change="handlePageChange"
        ></b-pagination>
      </div>
    </b-col>
    <b-modal id="modal-1" title="Events Log" ok-only size="lg">
      &nbsp;<b-button @click="getRecordingEvents()" class="mr-2">
      Refresh
    </b-button>


      <b-table small :fields="fieldsSub" :items="events" class="text-break" :striped="true" :bordered="true"
               responsive="sm">

        <template #cell(status)="data">
          <div class="mb-2">Date: {{formatDate(data.item.created_at)}}</div>
          <div class="mb-2">Status:
          <span v-if="data.item.status == 1">Added to queue</span>
          <span v-if="data.item.status == 2">Failed download</span>
          <span v-if="data.item.status == 3">Started download</span>
          <span v-if="data.item.status == 4">Upload failed</span>
          <span v-if="data.item.status == 5">Copy finished</span>
          <span v-if="data.item.status == 6">Error upload</span>
          <span v-if="data.item.status == 7">Deleted in zoom</span>
          <span v-if="data.item.status == 8">Deleted in zoom when in queue</span>
          <span v-if="data.item.status == 9">Zoom unauthorised</span>
          </div>
          <div v-if="data.item.text != ''">Message: {{data.item.text}}</div>

        </template>

      </b-table>
    </b-modal>

  </b-container>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import moment from "moment-timezone";

export default {
  data() {
    return {
      items: [
        {
          text: 'Copy status',
        }
      ],
      fields: [
        {
          key: 'video_id',
        },
        {
          key: 'display_name',
          label: 'User',
        },
        {
          key: 'topic',

        },
        {
          key: 'file_type',
        },
        {
          key: 'file_extension',
        },
        {
          key: 'file_size',
        },
        {
          key: 'copy_status',
          sortable: true,
          formatter: (value) => {
            if (value == 1) {
              return 'queued';
            }

            if (value == 2) {
              return 'started';
            }

            if (value == 3) {
              return 'completed';
            }

            if (value == 4) {
              return 'failed';
            }



            return '';
          }
        },
        {
          key: 'uploaded_size',
        },
        {
          key: 'actions',
          label: 'Actions',
        }
      ],
      pageOptions: [10, 20, 30],
      perPage: 20,
      page: 1,
      total: 0,
      isBusy: false,
      searchKeyword: '',
      striped: true,
      filter: false,
      sortBy: '',
      sortDirection: '',
      videos: [],
      types: [
        {
          value: '0',
          text: 'All'
        },
        {
          value: '1',
          text: 'Queued'
        },
        {
          value: '3',
          text: 'Completed'
        },
        {
          value: '2',
          text: 'Started'
        },
        {
          value: '4',
          text: 'Failed'
        },
    ],
      fieldsSub: [


        {
          key: 'status',
          label: 'Event',
          sortable: false,
        },


      ],
      type:'0',
      events: [],
      recordingId: '',
      updated: ''
    }
  },
  created() {
    this.getLocalVideos()
  },
  methods: {
    ...mapActions(['getUploadStatus', 'getEvents','ignore']),
    ...mapState(['getVideosState', 'getVideosTotal', 'getVideosPage']),

    getRequestParams(searchKeyword, page, perPage, sortBy, sortDirection) {
      let params = {};

      if (searchKeyword) {
        params["keyword"] = searchKeyword;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["perPage"] = perPage;
      }

      if (sortBy) {
        params["sortBy"] = sortBy;
      }

      if (sortDirection) {
        params["sortDirection"] = sortDirection;
      }

        params["type"] = this.type;

      return params;
    },
    getLocalVideos() {

      const params = this.getRequestParams(
          this.searchKeyword,
          1,
          this.perPage,
          this.sortBy,
          this.sortDirection
      );

       this.getUploadStatus(params)
            .then((response) => {
              this.total = response.data.total
              this.videos = response.data.videos
            })
    },
    sortingChanged(ctx) {

      this.sortBy = ctx.sortBy
      this.sortDirection = ctx.sortDesc ? 'desc' : 'asc'
      const params = this.getRequestParams(
          this.searchKeyword,
          this.page,
          this.perPage,
          this.sortBy,
          this.sortDirection
      );


       this.getUploadStatus(params)
        .then((response) => {
          this.total = response.data.total
          this.videos = response.data.videos
        })
    },
    handlePageChange(value) {

      this.page = value
      const params = this.getRequestParams(
          this.searchKeyword,
          value,
          this.perPage,
          this.sortBy,
          this.sortDirection
      );

       this.getUploadStatus(params)
        .then((response) => {
          this.videos = response.data.videos
        })
    },
    handlePageSizeChange(value) {


      const params = this.getRequestParams(
          this.searchKeyword,
          1,
          value,
          this.sortBy,
          this.sortDirection
      );


       this.getUploadStatus(params)
        .then((response) => {
          this.total = response.data.total
          this.videos = response.data.videos
        })
    },
    handleFilterChange() {
      this.page = 1
      const params = this.getRequestParams(
          this.searchKeyword,
          1,
          this.perPage,
          this.sortBy,
          this.sortDirection
      );


       this.getUploadStatus(params)
        .then((response) => {
          this.total = response.data.total
          this.videos = response.data.videos
        })
    },
    formatDate(value) {
      return moment(value).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
    },
    getRecordingEvents() {


      this.getEvents(this.recordingId)
          .then((response) => {
            this.events = response.data.events
          })

    },
    showModal(id) {

      this.recordingId = id
      this.getEvents(id)
          .then((response) => {
            this.events = response.data.events
          })

      this.$root.$emit('bv::show::modal', 'modal-1')
    },
    ignoreVideo(id) {

      this.ignore(id)
          .then((response) => {
            if(response.data.status) {
              this.updated = id
              setTimeout(() => this.updated = '', 3000)
            }
          })

    },
  },
}
</script>

<style>
.breadcrumb {
  background-color: #d1ecf1;
}
</style>
